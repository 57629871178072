.custom-dropdown {
  padding: 20px;
  display: flex;
  width: 14.28571rem;
  flex-direction: column;
  align-items: flex-start;
  border-radius: var(--radius-md, 0.57143rem);
  border: 1px solid var(--Colors-Border-border-secondary, #eaecf0);
  background: var(--Colors-Background-bg-primary, #fff);
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}
.dropdown-form {
  display: flex;
  padding: var(--spacing-xs, 0.28571rem) 0rem;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  line-height: 2.5rem;
}

.dropdown-toggle-btn {
  width: 150px;
  min-width: 150px;
  text-align: left;
  padding: 8px 12px;
  border: 0;
  border-radius: 6px;
  background: #ffffff;
  font-size: 14px;
  color: #333;
  min-height: 26px;
  height: 26px;
  align-items: center;
  display: flex;
}

.multiple_checkbox_style .form-check-input {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 1px solid lightgray;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.multiple_checkbox_style .form-check-input:checked {
  background-color: #007bff;
  border-color: #007bff;
}

.multiple_checkbox_style .form-check-input:checked::after {
  content: '✔';
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.multiple_checkbox_style .form-check-label {
  margin-left: 8px;
  color: #344054;
  font-family: Inter;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.42857rem;
}

.dropdown-buttons {
  display: flex;
  padding: 0.85714rem;
  gap: 0.57143rem;
  align-self: stretch;
  width: 100%;
  border-top: 2px solid var(--Colors-Border-border-secondary, #eaecf0);
}

.apply-btn {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  padding: var(--spacing-md, 0.57143rem) var(--spacing-lg, 0.85714rem);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-xs, 0.28571rem);
  flex: 1 0 0;
  border-radius: var(--radius-md, 0.57143rem);
  border: 1px solid
    var(--Component-colors-Components-Buttons-Primary-button-primary-border, #155eef);
  background: var(--Component-colors-Components-Buttons-Primary-button-primary-bg, #155eef);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.clear-btn {
  font-size: 14px;
  font-weight: 500;
  color: var(
    --Component-colors-Components-Buttons-Secondary-color-button-secondary-color-fg,
    #004eeb
  );
  display: flex;
  padding: var(--spacing-md, 0.57143rem) var(--spacing-lg, 0.85714rem);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-xs, 0.28571rem);
  flex: 1 0 0;
  border-radius: var(--radius-md, 0.57143rem);
  border: 1px solid
    var(
      //   --Component-colors-Components-Buttons-Secondary-color-button-secondary-color-border,
      #84adff
    );
  background: var(
    --Component-colors-Components-Buttons-Secondary-color-button-secondary-color-bg,
    #fff
  );

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
