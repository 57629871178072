@import '../../scss/variables';

.work-order-filter {
  position: relative;
  z-index: 999;
}

.draft-work-order-text {
  font-size: 15px;
  font-weight: bold;
  margin-left: 14px;
}

.column-filter-input {
  height: 26px;
  padding: 3px 5px 3px 5px;
  background: #ffffff !important;
  border: 0;
  border-radius: 6px;
}

.column-filter-input-icon {
  height: 26px;
  border: 0;
  background: #ffffff !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.table .thead-light .thead-main th {
  background-color: #f9fafb !important;
}

.brand-wo-table {
  height: 100%;
  overflow: scroll;

  tr {
    :last-child {
      .td {
        border-bottom: 0;
        margin: 0 !important;
      }
    }
  }

  th,
  td {
    background-color: #fff;

    .resizer {
      display: inline-block;
      width: 5px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateX(50%);
      z-index: 1;

      &.isResizing {
        background: red;
      }
    }
  }

  &.sticky {
    overflow: scroll;
    thead {
      position: sticky;
      z-index: 1;
      width: fit-content;
      top: 0;
      border-bottom: 1px solid #eaecf0;
    }

    .body {
      position: relative;
      z-index: 0;
    }

    thead tr th:first-child,
    td:first-child {
      position: sticky !important;
      left: 0;
      z-index: 2;
      width: 50px !important;
      flex: none !important;
    }

    thead tr th:nth-child(2),
    td:nth-child(2) {
      position: sticky !important;
      left: 50px;
      z-index: 2;
      border-right: 2px solid #eaecf0;
    }

    thead tr th:last-child,
    td:last-child {
      position: sticky !important;
      right: 0;
      z-index: 0;
      border-left: 2px solid #eaecf0;
    }
    thead tr.filters th:not(:first-child) {
      background-color: #f2f4f7;
      padding: 10px 5px !important;
      box-sizing: border-box;
      flex: 150 0 auto;
      min-width: 0px;
    }
  }

  thead {
    tr:not(.filter-row) {
      th {
        border: none;
        color: #475467;
        height: 44px;
        display: flex !important;
        font-size: 12px;
        font-weight: 600;
        width: 150px;
      }
      th:nth-child(3) {
        width: 250px !important;
      }
    }
  }

  tbody {
    tr td:nth-child(3) {
      width: 250px !important;
    }
  }
}

.work-order-wrapper {
  display: flex;
  gap: 1.5rem;
  .work-order-card {
    flex: 1;
  }
}

.work-order-summary {
  background-color: #f2f4f7;

  h6 {
    font-size: 14px;
    font-weight: 500;
    color: #475467;
  }

  h4 {
    font-weight: 600 !important;
    color: #344054;
  }

  .brand-count {
    width: max-content;
    height: 22px;
    border-radius: 16px;
    border: 1px solid #eaecf0;
    padding: 4px 8px;
    background-color: #f9fafb;
    font-size: 12px;
  }
}

.react-select.column-filter-dropdown .select__control {
  height: 26px;
  border: 0;
  min-height: 26px;
  background: #ffffff;
  min-width: 130px;
}

.react-select.column-filter-dropdown .select__control .select__placeholder,
.react-select.column-filter-dropdown .select__control .select__single-value {
  top: 44%;
  font-size: 12px;
}

.react-select.column-filter-dropdown .select__control .select__indicator {
  padding-top: 2px;
}

.react-select.column-filter-dropdown .select__control .select__indicator-separator {
  width: 0 !important;
}

.react-select.column-filter-dropdown .select__menu {
  z-index: 999 !important;
  width: auto !important;
}

.active-card {
  border: 1px solid #175cd3;
}

.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^='top'] > .arrow::after {
  border-top-color: #000 !important;
}

.brand-work_order-cards {
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  border-right: 1px solid #d0d5dd;
}
#last-card {
  border-right: none;
}

.brand-wo-card-container {
  border: 1px solid #eaecf0;
  padding: 10px 12px;
  margin: 0.5rem;
  width: Fill (1, 292px);
}

.table-responsive:has(.brand-wo-table) {
  min-height: 500px;
}
