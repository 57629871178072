.vendor-pricing-table {
  border: 1px solid #d0d5dd;

  thead tr th {
    background-color: #f2f4f7;
    color: #344054;
    font-size: 12px;
    font-weight: 500;
    border-right: 1px solid #d0d5dd;
    text-align: center;
    border-bottom: 0;
  }

  tbody tr td {
    color: #344054;
    font-size: 14px;
    font-weight: 400;
    border-right: 1px solid #d0d5dd;
    text-align: center;
  }

  tbody tr td:nth-child(2),
  tbody tr td:nth-child(4),
  thead tr th:nth-child(2),
  thead tr th:nth-child(4) {
    border-right: 0 !important;
  }

  tbody tr td:last-child,
  thead tr th:last-child {
    text-align: right;
  }

  tbody tr td:first-child {
    background-color: #f2f4f7;
    width: 180px;
    text-align: left;
    padding-left: 20px;
  }

  tbody tr td:last-child {
    width: 180px;
  }

  tbody tr td .updated-date {
    font-size: 12px;
    color: #98a2b3;
  }
}

.brand-pricing-table {
  border: 1px solid #d0d5dd;

  thead tr th {
    background-color: #f2f4f7;
    color: #344054;
    font-size: 12px;
    font-weight: 500;
    border-right: 1px solid #d0d5dd;
    text-align: center;
    border-bottom: 0;
  }

  tbody tr td {
    color: #344054;
    font-size: 14px;
    font-weight: 400;
    border-right: 1px solid #d0d5dd;
    text-align: center;
  }

  tbody tr td:first-child {
    background-color: #f2f4f7;
    width: 180px;
    text-align: left;
    padding-left: 20px;
  }

  tbody tr td:nth-child(2),
  tbody tr td:nth-child(4),
  thead tr th:nth-child(2),
  thead tr th:nth-child(4) {
    border-right: 0 !important;
  }
}

.edit-btn {
  border: 1px solid #84adff;
  background-color: #fff !important;
  color: #004eeb !important;
  font-weight: 600;
}

.edit-btn:hover,
.edit-btn:focus {
  color: #004eeb !important;
}

.cancel-btn {
  border: 1px solid #d0d5dd;
  background-color: #fff;
  color: #344054;
  font-weight: 600;
}

.margin-approval {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0;

  button {
    color: #344054;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
  }
}

.pricing-tabs {
  border-radius: 0;
  border: 0;
}

.margin-approval-metrics {
  border: 0;
  border-radius: 0;

  .card {
    border: 0;
  }

  p {
    font-weight: 400;
    font-size: 18px;
  }
  b {
    font-weight: 600;
  }

  .categories {
    background-color: #f9fafb;
    margin-right: -1%;
    margin-left: -1%;
    padding: 0 15px;
    border-top: 1px solid #eaecf0;
    border-bottom: 1px solid #eaecf0;

    span.profit {
      color: #079455;
    }

    span.loss {
      color: #d92d20;
    }
  }

  .row div:not(:last-child) {
    border-right: 1px solid #d0d5dd;
  }

  .row div:not(:first-child) {
    padding-left: 25px;
  }
}

.vendor-pricing {
  height: 100%;
  overflow: scroll;
  width: 100%;

  thead {
    tr th {
      background-color: #f9fafb;
      font-weight: 500;
      font-size: 12px;
      color: #344054;
    }
    tr.filters th {
      background-color: #f2f4f7;
    }
  }

  tbody {
    tr th {
      color: #344054;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.brand-price-table {
  width: 100%;
  height: 100%;
  margin: 0;

  thead {
    background: #f9fafb;
    th {
      border-width: 1px;
      border-style: inset;
      border-color: #eaecf0;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      text-align: left;
      color: #475467;
      width: 150px;
    }

    th.text-right {
      text-align: right;
    }
    th:first-child {
      width: 60px;
    }
    th:nth-last-child(1) {
      max-width: 10%;
    }

    th:has(div.align-right) {
      text-align: right !important;
    }
  }
  tbody {
    td {
      border-width: 1px;
      border-style: inset;
      border-color: #eaecf0;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #475467;
      width: 150px;
    }
    td:first-child {
      width: 60px;
    }

    tr.nested-table-head {
      td {
        .divider {
          margin-top: 10px;
        }
      }
      td:not(:first-child) {
        background-color: #eff4ff;
        font-size: 12px;
        font-weight: 500;
        color: #475467;
      }

      td:not(:first-child) {
        border-top: 1px solid #b2ccff;
      }

      td:nth-child(2) {
        border-left: 1px solid #b2ccff;
      }

      td:last-child {
        border-right: 1px solid #b2ccff;
      }

      td:first-child {
        border: 0 !important;
        border-top: 0 !important;
      }

      td:has(div.align-right) {
        text-align: right !important;
      }
    }

    tr.nested-table-body {
      td:nth-child(2) {
        border-left: 1px solid #b2ccff;
      }

      td:last-child {
        border-right: 1px solid #b2ccff;
      }

      td:first-child {
        border: 0;
      }

      td:has(div.align-right) {
        text-align: right !important;
      }
    }

    tr.nested-table-body.last-row td:not(:first-child) {
      border-bottom: 1px solid #b2ccff;
    }

    tr.nested-table-body.last-row td {
      .divider {
        margin-bottom: 20px;
      }
    }

    td:has(div.align-right) {
      text-align: right !important;
    }
  }
}

.icon-wrapper {
  padding: 5px;
  border-radius: 8px;
  border: 1px solid #b2ccff;
  box-shadow: 0px 1px 2px 0px #1018280d;
  background-color: #eff4ff;
  cursor: pointer;
}
.brand-price-approval-btn {
  color: var(--colors-text-text-success-primary-600, #079455);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.brand-price-rejection-btn {
  color: var(--colors-text-text-error-primary-600, #d92d20);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.approve-button {
  font-size: 14px;
  font-weight: 600;
  color: #079455 !important;

  img {
    margin-top: -2px;
  }
}

.reject-btn {
  font-size: 14px;
  font-weight: 600;
  color: #d92d20 !important;

  img {
    margin-top: -2px;
  }
}

.state-count {
  height: 23px;
  width: 23px;
  border: 1px solid #5d5e74;
  text-align: center;
  border-radius: 50%;
  color: #5d5e74;
  font-size: 12px;
  display: inline-block;
  padding: 2px;
  margin-left: 5px;
}

.Toastify__toast-container--top-center {
  left: 43% !important;
}

.Toastify__toast-container:has(.custom-background) {
  width: 550px !important;
}

.Toastify__toast.Toastify__toast--success.custom-background {
  background-color: #ecfdf3 !important;
  color: #344054;
  border: 1px solid #75e0a7;
  border-radius: 12px;
  width: fit-content;
  height: 52px;
  font-size: 14px;
  font-weight: 600;
}

.margin-approval-logs {
  thead {
    background-color: #f9fafb;
    border: solid 1px #eaecf0;
  }

  tbody {
    border: solid 1px #eaecf0;
  }

  thead tr th:first-child {
    width: 250px;
  }

  thead tr th:nth-child(2) {
    width: 180px;
  }
}

.table-responsive {
  border-bottom: 0;
}
