.newexecutionContainer {
  gap: 24px;
  padding: 16px 24px;
  background: #ffffff;
}
.execution_form_label {
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}
