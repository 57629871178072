@import 'src/scss/variables';
.WorkOrderDetails {
  .buttons {
    .btn-outline-secondary {
      padding: 7px 12px 7px 12px;
      font-size: 1.143rem;
    }
  }
  .details-filter {
    border-bottom: 1px solid #eaecf0;

    h6 {
      color: #000000;
      font-size: 1.143rem;
      font-family: $headings-font-family;
    }
    // p {
    //   font-size: 1rem;
    //   color: #000000;
    //   font-family: $font-family-base;
    // }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.brand-info {
  white-space: nowrap;
}
.work-order-log.modal-body {
  height: auto;
  overflow-y: scroll;
}

.work-order-log-modal .modal-title {
  font-size: 20px;
}

.table-wrapper {
  height: auto;
  overflow: auto;
  border: 1px solid #eaecf0;
}

.work-order-log-table {
  border: none;
  border-collapse: collapse;
  th {
    background-color: #f9fafb;
  }
  .thead_logs {
    background-color: #f9fafb;
    border: solid 1px #eaecf0;
  }
  thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  thead td {
    font-size: 12px;
    font-weight: 600;
    color: #344054;
    font-family: Inter;
    padding: 12px 24px;
  }
  tbody {
    overflow-y: auto;
  }
  tbody td {
    white-space: normal;
    vertical-align: top !important;
    font-size: 14px !important;
    font-family: Inter;
    font-weight: 400;
    padding: 16px 24px;
  }

  tbody td:nth-last-child(-n + 2) {
    width: 377px;
  }
  .audit_log_prev-value,
  .audit_log_current-value {
    color: #667085;
    font-family: Inter;
    font-weight: 700;
    font-size: 14px;
    white-space: break-spaces;
    word-wrap: break-word;
    padding-left: 0.4rem;
    overflow: auto;
  }
  .audit_log_remarks {
    color: #667085;
    font-weight: 700;
    font-size: 14px;
    font-family: Inter;
    white-space: break-spaces;
    word-wrap: break-word;
    overflow: auto;
    padding-left: 0.4rem;
    width: 20vw;
  }
  .audit_log_text_value {
    color: #667085;
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    white-space: break-spaces;
    word-wrap: break-word;
    overflow: auto;
    display: flex;
    flex-direction: row;
  }
  .text_value_action_user {
    color: #475467;
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
  }
}

.edit_btn_brand {
  border: 2px solid #84adff;
  color: #004eeb;
  font-family: Inter;
  padding: 10px 14px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
}
.close_wo_button {
  font-family: Inter;
  cursor: pointer;
  font-size: 14px;
  color: #b42318;
  font-weight: 600;
  line-height: 20px;
  border: 2px solid #fda29b;
  border-radius: 8px;
  background-color: white;
  padding: 10px 14px;
}
.success_wo_button {
  font-family: Inter;
  cursor: pointer;
  font-size: 14px;
  color: #00a86b;
  font-weight: 600;
  line-height: 20px;
  border: 2px solid #29ab87;
  border-radius: 8px;
  background-color: white;
  padding: 10px 14px;
}
.audit_trail_button {
  font-family: Inter;
  font-size: 14px;
  color: #344054;
  font-weight: 600;
  line-height: 20px;
  border: 2px solid #d0d5dd;
  border-radius: 8px;
  background-color: white;
  padding: 10px 14px;
  cursor: pointer;
}
.wotitleflex {
  display: flex;
  gap: 14px;
}
.field_wodetails {
  color: #000000;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  white-space: break-spaces;
}
.field_wodetails_rejection_comments {
  color: #000000;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  overflow: auto;
  white-space: break-spaces;
  height: 100px;
}
.label_wodetails {
  color: #475647;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  white-space: break-spaces;
}
.comments_btn_brand {
  position: relative;
  border: 2px solid #d0d5dd;
  font-family: Inter;
  font-size: 14px;
  border-radius: 8px;
  background-color: white;
  font-weight: 600;
  color: #344054;
  padding: 8px 12px;
  line-height: 20px;
  cursor: pointer;
}
.fulfilment_plan_button {
  font-family: Inter;
  font-size: 14px;
  cursor: pointer;
  color: #475467;
  font-weight: bold;
  line-height: 20px;
}
.rejection_div {
  background-color: #fee4e2;
  display: flex;
  justify-content: start;
  align-items: center;
  border: 1px solid #eaecf0;
}
.rejection_comment {
  padding: 12px 32px;
  font-family: Inter;
  font-size: 16px;
  color: #344054;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin: 0;
}
.rejection_comment_red {
  color: #d92d20;
}
.badge_design_brandwo {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
}
.save_button_brand {
  font-family: Inter;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 10px 14px;
  gap: 4px;
  border-radius: 8px;
  border: 2px solid #155eef;
}
.vendor_documents_button_style {
  font-family: Inter;
  font-size: 14px;
  color: #247cfa;
  font-weight: 600;
  line-height: 20px;
  border: 2px solid #dee6fe;
  border-radius: 8px;
  background-color: white;
  padding: 10px 14px;
  cursor: pointer;
}
.comments-button-count {
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translate(50%, -50%);
  padding: 2px 8px;
  border-radius: 16px;
  color: #fff;
  font-size: 1rem;
}
.enabled-button-cnt {
  background-color: #c73a3a;
}
.disabled-button-cnt {
  background-color: #d0d5dd;
}

.link-style-span {
  color: #004eeb;
  text-decoration: underline;
  cursor: pointer;
}

.work-order-log-table.bdelivery-audit {
  thead {
    background-color: #f9fafb !important;
    tr:nth-child(2) th {
      font-weight: 500 !important;
      border: 1px solid #eaecf0 !important;
    }
    tr:nth-child(1) th {
      font-weight: 600 !important;
      border: 1px solid #eaecf0 !important;
    }
  }
  tbody {
    td {
      border: 1px solid #eaecf0 !important;
      white-space: normal;
      vertical-align: top !important;
      font-size: 14px !important;
      font-family: Inter;
      font-weight: 400;
      padding: 16px 24px;
    }
  }
}

.work-order-metrics {
  background-color: #f9fafb;
  padding: 0 15px;
  border-top: 1px solid #eaecf0;
  border-bottom: 1px solid #eaecf0;
  border-radius: 8px;
  margin: 15px 0;

  .row div:not(:last-child) {
    border-right: 1px solid #d0d5dd;
  }

  h6 {
    color: #475467;
  }
}
